import Vue from 'vue'
import App from './App.vue'
import Pizzicato from 'pizzicato';
import VueRouter from 'vue-router'
import IntroPage from './pages/IntroPage'
import StemPlayer from './pages/StemPlayer'

Vue.config.productionTip = false


Vue.use(Pizzicato);
Vue.use(VueRouter);

const routes = [
        { path: '/', component: IntroPage },
        { path: '/stemplayer', component: StemPlayer },
        { path: '*', component: IntroPage }
];

const router = new VueRouter({
        routes // short for `routes: routes`
})
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
