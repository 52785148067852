<template>
  <div class="play" :class="{ inactive: !ready }">
    <button v-if="!ready">Loading...</button>
    <button v-if="ready" @click="playing ? pauseSound() : playSound()">
      {{ playing ? "Pause" : "Play" }}
    </button>

    <button v-if="started && ready" @click="stopSound">Stop</button>
  </div>
</template>

<script>
export default {
  name: "Track",
  props: {
    audioFile: String,
  },
  data: function () {
    return {
      playing: false,
      started: false,
      ready: 0,
    };
  },
  mounted() {
    this.$root.$on("ready", () => {
      this.ready++;
    });
  },
  methods: {
    playSound() {
      if (this.ready < 3) return;
      this.$root.$emit("play");
      this.playing = true;
      this.started = true;
    },
    pauseSound() {
      if (this.ready < 3) return;
      this.$root.$emit("pause");
      this.playing = false;
    },
    stopSound() {
      if (this.ready < 3) return;
      this.$root.$emit("stop");
      this.playing = false;
      this.started = false;
    },
  },
};
</script>

<style scoped>
button {
  background: none;
  border: none;
  font-size: 50px;
  display: block;
  margin: 6px 0px;
}

.play {
  margin-top: 50px;
  flex-direction: column;
  display: flex;
}

.inactive {
  opacity: 0.2;
}
</style>
