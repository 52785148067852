<template>
  <div class="track">
    <div>
      <div
        @mousedown="solo"
        @touchstart="solo"
        @mouseup="unsolo"
        @touchend="unsolo"
        class="label"
        :class="{ dim: muted || (!soloed && !nothingSoled) }"
      >
        {{ label }}
      </div>
      <button @click="toggleMute" class="mute-button">
        {{ muted ? "Unmute 🔇" : "Mute 🔈" }}
      </button>
    </div>
  </div>
</template>

<script>
const Pizzicato = require("/node_modules/pizzicato/distr/Pizzicato");

export default {
  name: "Track",
  props: {
    audioFile: String,
    label: String,
  },
  data: function () {
    return {
      sound: null,
      effectOn: false,
      muted: false,
      soloed: false,
      nothingSoled: true,
    };
  },
  mounted() {
    this.sound = new Pizzicato.Sound(
      {
        source: "file",
        options: { path: `/audios${this.audioFile}` },
      },
      () => {
        this.$root.$emit("ready");
      }
    );

    this.$root.$on("solo", (data) => {
      this.soloTrack(data);
    });

    this.$root.$on("unsolo", () => {
      if (!this.muted) {
        this.sound.volume = 1;
        this.soloed = false;
      }
      this.nothingSoled = true;
    });

    this.$root.$on("play", () => {
      this.playSound();
    });

    this.$root.$on("pause", () => {
      this.pauseSound();
    });

    this.$root.$on("stop", () => {
      this.stopSound();
    });
  },

  methods: {
    toggleMute() {
      if (this.muted) {
        this.unmute();
      } else {
        this.mute();
      }
    },
    playSound() {
      this.sound.play();
    },
    pauseSound() {
      this.sound.pause();
    },
    stopSound() {
      this.sound.stop();
    },
    solo() {
      this.$root.$emit("solo", this.audioFile);
    },
    unsolo() {
      this.$root.$emit("unsolo", this.audioFile);
    },
    mute() {
      this.sound.volume = 0;
      this.muted = true;
    },
    unmute() {
      this.sound.volume = 1;
      this.muted = false;
    },
    soloTrack(fileName) {
      this.sound.volume = fileName === this.audioFile ? 1 : 0;

      this.soloed = this.sound.volume === 1;
      this.muted = this.soloed ? false : this.muted;
      this.nothingSoled = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.track {
  display: flex;
}
.label {
  font-size: 85px;
  transition: all 0.1s;
}

.label:hover {
  cursor: pointer;
}

.mute-button {
  background: none;
  border: none;
  color: #eee;
  font-family: fog, "Times New Roman", Times, serif;
  font-size: 20px;
  margin: 5px 0px;
}

.dim {
  opacity: 0.5;
  transform: scale(0.5);
}
</style>
