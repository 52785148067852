var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"StemPlayer"}},[_c('img',{attrs:{"id":"logo","src":"/images/leap of faith logo.png"}}),(_vm.intro)?_c('div',{staticClass:"instructions"},[_c('p',[_vm._v(" Welcome to Legacy’s Leap of Faith Website. Inspired by Kanye West, we’ve included a stem player on each song, in order to get you immersed with the Leap Of Faith Experience. Enjoy... ")]),_c('p',{staticClass:"instruction-title"},[_vm._v("Intructions:")]),_vm._m(0),_c('button',{attrs:{"id":"start-button"},on:{"click":function($event){_vm.intro = false}}},[_vm._v("Start")]),_c('p')]):_vm._e(),(_vm.start && !_vm.intro)?_c('div',[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.metadata.title)+" "),(_vm.metadata.features != '')?_c('span',[_vm._v("ft. "+_vm._s(_vm.metadata.features))]):_vm._e()]),_c('h2',{staticClass:"prod"},[_vm._v("Prod by. "+_vm._s(_vm.metadata.producers))]),_c('div',{staticClass:"track-container"},_vm._l((_vm.tracks[_vm.selectedIndex]),function(track){return _c('Track',{key:track[0],attrs:{"audioFile":track[0],"label":track[1]}})}),1),_c('Play'),_c('button',{attrs:{"id":"back-button"},on:{"click":_vm.reloadPage}},[_vm._v("< back")])],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.start && !_vm.intro)?_c('div',{staticClass:"tracklist"},[_c('ul',[_c('li',{on:{"click":function($event){return _vm.startStems(0, {
              title: 'The Cycle',
              features: '',
              producers: 'Torino P',
            })}}},[_vm._v(" The Cycle ")]),_c('li',{on:{"click":function($event){return _vm.startStems(1, {
              title: 'Another World',
              features: '',
              producers: 'Torino P',
            })}}},[_vm._v(" Another World ")]),_c('li',{on:{"click":function($event){return _vm.startStems(2, {
              title: 'Take A Sip',
              features: 'J. Crum',
              producers: 'Torino P',
            })}}},[_vm._v(" Take A Sip ")]),_c('li',{on:{"click":function($event){return _vm.startStems(3, {
              title: 'Man Down',
              features: 'Dru Bex',
              producers: 'Torino P',
            })}}},[_vm._v(" Man Down ")]),_c('li',{on:{"click":function($event){return _vm.startStems(4, {
              title: 'Last Daze',
              features: '',
              producers: 'Torino P',
            })}}},[_vm._v(" Last Daze ")]),_c('li',{on:{"click":function($event){return _vm.startStems(5, {
              title: 'Faith Over Fear',
              features: '',
              producers: 'Torino P',
            })}}},[_vm._v(" Faith Over Fear ")]),_c('li',{on:{"click":function($event){return _vm.startStems(6, {
              title: 'Burdened',
              features: 'Scribe Music & Eshon Burgandy',
              producers: 'Torino P',
            })}}},[_vm._v(" Burdened ")]),_c('li',{on:{"click":function($event){return _vm.startStems(7, {
              title: 'No Good',
              features: '',
              producers: 'Torino P',
            })}}},[_vm._v(" No Good ")]),_c('li',{on:{"click":function($event){return _vm.startStems(8, {
              title: 'Didn\'t Work',
              features: '',
              producers: 'Torino P',
            })}}},[_vm._v(" Didn't Work ")]),_c('li',{on:{"click":function($event){return _vm.startStems(9, {
              title: 'Let Me Walk',
              features: '',
              producers: 'Torino P & Sean Mcswiggan',
            })}}},[_vm._v(" Let Me Walk ")])])]):_vm._e()]),_c('img',{class:{ footerDim: _vm.intro },attrs:{"id":"footer","src":"/images/leap of faith website image.png"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"instruction-list"},[_c('li',[_vm._v("Tap/Click on icons representing a track channel to solo.")]),_c('li',[_vm._v("Tap/Click on mute button underneath icons to mute a track.")])])}]

export { render, staticRenderFns }