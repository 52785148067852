<template>
  <div id="introPage">
    <img id="logo" src="/images/leap of faith logo.png" />
    <div class="platform-links">
      <h1>Album Out Now on:</h1>
      <div class="links">
        <a
          id="spotify"
          target="_blank"
          href="https://open.spotify.com/album/32GocDhfBIAOeMcDQjgcCH?si=YciZyqWzT92jq5ySJ_V0dA"
          ><img src="/images/spotify-badge.svg" alt=""
        /></a>
        <a
          id="apple"
          href="https://music.apple.com/us/album/leap-of-faith/1581880546?uo=4&app=music&at=1001lry3&ct=dashboard"
          target="_blank"
          ><img src="/images/apple-music-badge.svg" alt=""
        /></a>
      </div>
      <a
        id="other-dsp"
        target="_blank"
        href="https://distrokid.com/hyperfollow/legacy11/leap-of-faith-7"
        >and all other platorms...</a
      >
    </div>

    <iframe class="video" src="https://www.youtube.com/embed/CajO8ygwgR0">
    </iframe>

    <router-link id="stemplayer" to="/stemplayer"
      >Experience the Stem Player &gt;</router-link
    >

    <div class="socials">
      <a href="https://vm.tiktok.com/ZMdV7CJdD" id="ttk"
        ><img
          src="https://cdx.solo.to/images/social/tiktok-min.svg"
          alt="TikTok"
      /></a>
      <a href="https://open.spotify.com/artist/4nruWqySt1ADCAFF1hj8vE" id="spt"
        ><img
          src="https://cdx.solo.to/images/social/spotify-min.svg"
          alt="Spotify"
      /></a>
      <a href="https://youtube.com/channel/UCmwTm8dfOvyeEwuNASiF6VQ" id="ytb"
        ><img
          src="https://cdx.solo.to/images/social/youtube-min.svg"
          alt="YouTube"
      /></a>
      <a href="https://twitter.com/legacyisborn_" id="twt"
        ><img
          src="https://cdx.solo.to/images/social/twitter-min.svg"
          alt="Twitter"
      /></a>
      <a href="https://instagram.com/legacyisborn" id="ist"
        ><img
          src="https://cdx.solo.to/images/social/instagram-min.svg"
          alt="Instagram"
      /></a>
    </div>
    <img id="footer" src="/images/leap of faith website image.png" />
  </div>
</template>

<script>
export default {
  name: "IntroPage",
};
</script>

<style>
.platform-links {
  color: #eee;
}

.platform-links a {
  color: #fde400;
  font: 20px;
}

.platform-links a img {
  max-width: 150px;
  height: 100px;
  margin: 0 10px;
}

#other-dsp {
  text-decoration: none;
  font-size: 20px;
}

#logo {
  max-width: 250px;
}

#stemplayer {
  color: #eee;
  font-weight: bold;
  text-decoration: none;
  font-size: 25px;
  margin: 25px 0px;
}

.socials {
  display: flex;
  justify-content: space-around;
  padding: 25px 0px;
  max-width: 310px;
  margin: auto;
}

.socials a {
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: #060606;
}

.socials img {
  filter: invert(100%);
}

.video {
  max-width: 300px;
  width: 100%;
  height: 100%;
  margin: 30px auto;
  display: block;
}

#footer {
  position: fixed;
  max-width: 100vw;
  left: 0;
  bottom: -30vw;
  z-index: -1;
  transition: opacity 4s ease-in-out;
  transition: transform 4s ease-in-out;
  opacity: 0.4;
}

@media only screen and (min-width: 600px) {
  #footer {
    opacity: 0.4;
  }
}
</style>