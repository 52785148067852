<template>
  <div id="app"><router-view></router-view></div>
</template>

<script>
export default {};
</script>

<style>
@font-face {
  font-family: "fog"; /*a name to be used later*/
  src: url("/fonts/FogtwoNo5.otf"); /*URL to font*/
}

#app {
  font-family: fog, "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  background-color: #060606;
}

.tracklist {
  display: flex;
  justify-content: center;
}

.tracklist li {
  color: #fde400;
  padding: 2px;
  margin-top: 10px;
  font-size: 22px;
}
.tracklist li:hover {
  cursor: pointer;
}
.tracklist ul {
  list-style-type: lower-roman;
}

#logo {
  max-width: 250px;
}

#footer {
  position: fixed;
  max-width: 100vw;
  left: 0;
  bottom: -30vw;
  z-index: -1;
  transition: opacity 4s ease-in-out;
  transition: transform 4s ease-in-out;
}

@media only screen and (min-width: 600px) {
  #footer {
    opacity: 0.6;
  }
}

.track-container {
  display: flex;
  justify-content: space-around;
  max-width: 620px;
  margin: auto;
  margin-top: 40px;
}

* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  touch-action: manipulation;
}

button {
  background: none;
  border: none;
  color: #fde400;
  font-family: fog, "Times New Roman", Times, serif;
  font-size: 25px;
}

.instructions {
  color: #eee;
  margin: 70px auto;
  max-width: 620px;
  text-align: left;
}

ul.instruction-list li {
  text-align: left;
  margin: auto;
}

#start-button {
  margin-top: 15px;
}
#back-button {
  margin-top: 25px;
}

.footerDim {
  opacity: 0 !important;
  transform: translateY(10vh);
}

.fade-enter-active {
  transition: opacity 2s ease-in-out;
}

.fade-leave-active {
  transition: opacity 0.05s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.socials {
  display: flex;
  justify-content: space-around;
  padding: 25px 0px;
  max-width: 310px;
  margin: auto;
}

.socials a {
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: #060606;
}

.socials img {
  filter: invert(100%);
}
</style>
