<template>
  <div id="StemPlayer">
    <img id="logo" src="/images/leap of faith logo.png" />
    <div v-if="intro" class="instructions">
      <p>
        Welcome to Legacy’s Leap of Faith Website. Inspired by Kanye West, we’ve
        included a stem player on each song, in order to get you immersed with
        the Leap Of Faith Experience. Enjoy...
      </p>

      <p class="instruction-title">Intructions:</p>
      <ul class="instruction-list">
        <li>Tap/Click on icons representing a track channel to solo.</li>
        <li>Tap/Click on mute button underneath icons to mute a track.</li>
      </ul>

      <button id="start-button" @click="intro = false">Start</button>
      <p></p>
    </div>
    <div v-if="start && !intro">
      <h1 class="title">
        {{ metadata.title }}
        <span v-if="metadata.features != ''">ft. {{ metadata.features }}</span>
      </h1>
      <h2 class="prod">Prod by. {{ metadata.producers }}</h2>
      <div class="track-container">
        <Track
          v-for="track in tracks[selectedIndex]"
          :key="track[0]"
          :audioFile="track[0]"
          :label="track[1]"
        />
      </div>
      <Play />
      <button @click="reloadPage" id="back-button">&lt; back</button>
    </div>
    <transition name="fade">
      <div v-if="!start && !intro" class="tracklist">
        <ul>
          <li
            @click="
              startStems(0, {
                title: 'The Cycle',
                features: '',
                producers: 'Torino P',
              })
            "
          >
            The Cycle
          </li>
          <li
            @click="
              startStems(1, {
                title: 'Another World',
                features: '',
                producers: 'Torino P',
              })
            "
          >
            Another World
          </li>
          <li
            @click="
              startStems(2, {
                title: 'Take A Sip',
                features: 'J. Crum',
                producers: 'Torino P',
              })
            "
          >
            Take A Sip
          </li>
          <li
            @click="
              startStems(3, {
                title: 'Man Down',
                features: 'Dru Bex',
                producers: 'Torino P',
              })
            "
          >
            Man Down
          </li>
          <li
            @click="
              startStems(4, {
                title: 'Last Daze',
                features: '',
                producers: 'Torino P',
              })
            "
          >
            Last Daze
          </li>
          <li
            @click="
              startStems(5, {
                title: 'Faith Over Fear',
                features: '',
                producers: 'Torino P',
              })
            "
          >
            Faith Over Fear
          </li>
          <li
            @click="
              startStems(6, {
                title: 'Burdened',
                features: 'Scribe Music & Eshon Burgandy',
                producers: 'Torino P',
              })
            "
          >
            Burdened
          </li>
          <li
            @click="
              startStems(7, {
                title: 'No Good',
                features: '',
                producers: 'Torino P',
              })
            "
          >
            No Good
          </li>
          <li
            @click="
              startStems(8, {
                title: 'Didn\'t Work',
                features: '',
                producers: 'Torino P',
              })
            "
          >
            Didn't Work
          </li>
          <li
            @click="
              startStems(9, {
                title: 'Let Me Walk',
                features: '',
                producers: 'Torino P & Sean Mcswiggan',
              })
            "
          >
            Let Me Walk
          </li>
        </ul>
      </div>
    </transition>
    <img
      id="footer"
      src="/images/leap of faith website image.png"
      :class="{ footerDim: intro }"
    />
  </div>
</template>

<script>
import Track from "../components/Track.vue";
import Play from "../components/Play.vue";

var thisC = null;
export default {
  name: "StemPlayer",
  components: {
    Track,
    Play,
  },
  data: function () {
    return {
      start: false,
      intro: true,
      metadata: {},
      tracks: [
        [
          ["/the-cycle/the-cycle-melodies.mp3", "🎹"],
          ["/the-cycle/the-cycle-vocals.mp3", "🎤"],
          ["/the-cycle/the-cycle-drums.mp3", "🥁"],
        ],
        [
          ["/another-world/another-world-melodies.mp3", "🎹"],
          ["/another-world/another-world-vocals.mp3", "🎤"],
          ["/another-world/another-world-drums.mp3", "🥁"],
        ],
        [
          ["/take-a-sip/take-a-sip-melodies.mp3", "🎹"],
          ["/take-a-sip/take-a-sip-vocals.mp3", "🎤"],
          ["/take-a-sip/take-a-sip-drums.mp3", "🥁"],
        ],
        [
          ["/man-down/man-down-melodies.mp3", "🎹"],
          ["/man-down/man-down-vocals.mp3", "🎤"],
          ["/man-down/man-down-drums.mp3", "🥁"],
        ],
        [
          ["/last-days/last-days-melodies.mp3", "🎹"],
          ["/last-days/last-days-vocals.mp3", "🎤"],
          ["/last-days/last-days-drums.mp3", "🥁"],
        ],
        [
          ["/fof/fof-melodies.mp3", "🎹"],
          ["/fof/fof-vocals.mp3", "🎤"],
          ["/fof/fof-drums.mp3", "🥁"],
        ],
        [
          ["/burdened/burdened-melodies.mp3", "🎹"],
          ["/burdened/burdened-vocals.mp3", "🎤"],
          ["/burdened/burdened-drums.mp3", "🥁"],
        ],
        [
          ["/no-good/no-good-melodies.mp3", "🎹"],
          ["/no-good/no-good-vocals.mp3", "🎤"],
          ["/no-good/no-good-drums.mp3", "🥁"],
        ],
        [
          ["/didnt-work/didnt-work-melodies.mp3", "🎹"],
          ["/didnt-work/didnt-work-vocals.mp3", "🎤"],
          ["/didnt-work/didnt-work-drums.mp3", "🥁"],
        ],
        [
          ["/let-me-walk/let-me-walk-melodies.mp3", "🎹"],
          ["/let-me-walk/let-me-walk-vocals.mp3", "🎤"],
          ["/let-me-walk/let-me-walk-drums.mp3", "🥁"],
        ],
      ],
      selectedIndex: 0,
    };
  },
  methods: {
    startStems: (index, metadata) => {
      thisC.selectedIndex = index;
      thisC.start = true;
      thisC.metadata = metadata;
    },
    reloadPage() {
      //       window.location.href =
      //         window.location.origin +
      //         window.location.pathname +
      //         "/stemplayer?introdone";

      thisC.$router.push({
        path: "stemplayer",
        query: { introdone: "yeslord" },
      });

      location.reload();
    },
    getParamToJSON(url, justParams) {
      var obj = {};

      /* logic for full url will only be run, if justParams is false, or null */
      if (justParams == null || !justParams) {
        url = url.split("?");

        /* get the part after the ? */
        if (url != null && url.length == 2) {
          url = url[1];
        } else {
          return obj;
        }
      }

      var pairs = url.split("&"); //gets key and value form the url

      /* organizes the pairs into JSON form */
      for (var i = 0; i < pairs.length; i++) {
        var temp = pairs[i].split("=");
        obj[temp[0]] = temp[1];
      }

      return obj;
    },
  },
  created() {
    thisC = this;

    let params = this.getParamToJSON(window.location.href, false);
    let isIntroDone = Object.prototype.hasOwnProperty.call(params, "introdone");

    if (isIntroDone) {
      this.intro = false;
    }
  },
};
</script>

<style>
@font-face {
  font-family: "fog"; /*a name to be used later*/
  src: url("/fonts/FogtwoNo5.otf"); /*URL to font*/
}

#app {
  font-family: fog, "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.title {
  color: #fde400;
  font-size: 30px;
  margin-bottom: 0px;
}

.title span {
  color: #da0000;
}

.prod {
  color: #eee;
  font-size: 15px;
  margin-top: 0px;
}
body {
  background-color: #060606;
}

.tracklist {
  display: flex;
  justify-content: center;
}

.tracklist li {
  color: #fde400;
  padding: 2px;
  margin-top: 10px;
  font-size: 22px;
}
.tracklist li:hover {
  cursor: pointer;
}
.tracklist ul {
  list-style-type: lower-roman;
}

#logo {
  max-width: 250px;
}

#footer {
  position: fixed;
  max-width: 100vw;
  left: 0;
  bottom: -30vw;
  z-index: -1;
  transition: opacity 4s ease-in-out;
  transition: transform 4s ease-in-out;
}

@media only screen and (min-width: 600px) {
  #footer {
    opacity: 0.4;
  }
}

.track-container {
  display: flex;
  justify-content: space-around;
  max-width: 620px;
  margin: auto;
  margin-top: 40px;
}

* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  touch-action: manipulation;
}

button {
  background: none;
  border: none;
  color: #fde400;
  font-family: fog, "Times New Roman", Times, serif;
  font-size: 25px;
}

.instructions {
  color: #eee;
  margin: 70px auto;
  max-width: 620px;
  text-align: left;
}

ul.instruction-list li {
  text-align: left;
  margin: auto;
}

#start-button {
  margin-top: 15px;
}
#back-button {
  margin-top: 25px;
}

.footerDim {
  opacity: 0 !important;
  transform: translateY(10vh);
}

.fade-enter-active {
  transition: opacity 2s ease-in-out;
}

.fade-leave-active {
  transition: opacity 0.05s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>